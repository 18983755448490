<template>
  <div class="bet-match">
    <!--   match teams names   -->
    <div class="bet-match__header">{{ betMatchInfo.homeTeamAcronym }}</div>
    <div class="bet-match__header">VS</div>
    <div class="bet-match__header">{{ betMatchInfo.awayTeamAcronym }}</div>
    <!--   match teams logos   -->
    <div class="bet-match__logo">
      <img :src="`${teamsLogosUrl}${betMatchInfo.homeTeamLogo}`" alt="" />
    </div>
    <div />
    <div class="bet-match__logo">
      <img :src="`${teamsLogosUrl}${betMatchInfo.awayTeamLogo}`" alt="" />
    </div>
    <!--   match team indicator   -->
    <template>
      <div class="bet-match__winner" :class="{ 'bet-match__winner--check': true }">
        <img v-if="betMatchInfo.isHomeTeamChecked" src="/icons/icn-check-winner.svg" alt="" />
      </div>
      <div />
      <div class="bet-match__winner" :class="{ 'bet-match__winner--check': false }">
        <img v-if="betMatchInfo.isAwayTeamChecked" src="/icons/icn-check-winner.svg" alt="" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BetMatch',
  props: {
    betMatchInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      teamsLogosUrl: 'https://az755631.vo.msecnd.net/teams-80/',
    };
  },
};
</script>

<style scoped lang="scss">
.bet-match {
  display: grid;
  grid-template-columns: repeat(3, min-content);

  &__header {
    font-size: 1.5em;
    font-weight: bold;
    font-family: Obvia-Condensed-SemiBold, sans-serif;
  }

  &__logo {
    height: 3.4em;
    width: 3.4em;
    border-radius: 50%;
    box-shadow: 1px 4px 7px -3px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 1px 4px 7px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 4px 7px -3px rgba(0, 0, 0, 0.75);

    & > img {
      object-fit: contain;
    }
  }

  &__winner {
    height: 2.1em;
    width: 2.1em;
    justify-self: center;
    border-radius: 50%;
    margin-top: 1em;

    &--check {
      border: none;
    }
  }

  @media screen and (max-width: 600px) {
    font-size: 0.7rem !important;
  }
}
</style>
